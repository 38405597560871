import React, { useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';

const SectionBackground = styled.div`
  background-color : ${props => props.theme.colors.white};
  color : ${props => props.theme.colors.gray_dark};
  padding-bottom:103px;
`
const TitleText = styled.div`
  color : ${props => props.theme.colors.accent_yellow_dark};
  font: 58px/62px Abril Fatface;
  padding-top:150px;
  margin-bottom:30px;
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    padding-top:30px;
  }
`
const CardAccordion = styled(Card)`
  border: 2px solid ${props => props.theme.colors.gray_border};
  border-left:none;
  border-right:none;
  .card-header {
    padding:0;
    background-color: ${props => props.theme.colors.white};
    border-bottom:none;
  }
  .card-body {
    text-align: justify;
    padding-left:5px;
    padding-top:0;
    padding-bottom:15px;
    span {
      margin-left:15px;
    }
    div {
      margin-top:10px;
    }
  }
  .row-video {
    img {
      width:122px;
      height:70px;
      object-fit:contain;
    }
  }
  .col-video{
    @media(min-width:1200px) {
      padding-right:0;
    }
  }
`
const FAArrow = styled(FontAwesomeIcon)`
  font-size:1.5rem;
  color: ${props => props.theme.colors.primary_violet};
  &:hover, &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.colors.primary_violet_dark};
  }
`

const ButtonRegistration = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto; 
  margin-top:10px;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_violet.hovered.shadow} !important;
    @media(min-width:1200px){
      margin-top:7px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1200px){
      margin-top:7px;
      margin-bottom:3px;
    }
  }
  @media(max-width:767px){
    width:100%;
    font-size:20px;
  }
  @media(max-width:320px){
    font-size:16px;
  }
`

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      css={css`
        font: Bold 20px/28px Roboto;
        outline:none;
        padding-top:30px;
        padding-bottom:30px};
        @media(max-width:767px){
          padding-top:15px;
          padding-bottom:15px};
        }
      `}
      onClick={decoratedOnClick}
      onKeyDown={decoratedOnClick}
      role="button"
      tabIndex={0}
    >
      <Row>
        <Col xs="10" md="11">
          {children}
        </Col>
        <Col xs="2" md="1" className="d-flex justify-content-end">
          <FAArrow icon={isCurrentEventKey ? faArrowAltCircleDown : faArrowAltCircleRight} />
        </Col>
      </Row>
    </div>
  );
}

export default function HeForSheSection3() {
  return (      
    <SectionBackground>
      <Container>
        <Row>
          <Col lg="2">
          </Col>
          <Col lg="9">
            <TitleText>
              Here’s how it works:
              <br></br>Become a mentor in 3 steps.
          </TitleText>
          <Accordion defaultActiveKey="0" css={css`margin-bottom:25px;`}>
            <CardAccordion>
              <Card.Header>
                <ContextAwareToggle eventKey="0">1. Fill out the WomenWorks Stronger TogetHER mentor registration form</ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                    Fill out our mentor registration form with your expertise, career story and motivations..
                </Card.Body>
              </Accordion.Collapse>
            </CardAccordion>
            <CardAccordion>
              <Card.Header>
                <ContextAwareToggle eventKey="1">2. Get contacted by a WomenWorks Representative</ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                    We will hold an interview session with you to get to know you better and see whether you fit with our program.
                </Card.Body>
              </Accordion.Collapse>
            </CardAccordion>
            <CardAccordion>
              <Card.Header>
                <ContextAwareToggle eventKey="2">3. Join a WomenWorks Mentor Training session</ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>Our Mentor Relations team will arrange mentor guidelines and training session for you (more details will be provided later).</Card.Body>
              </Accordion.Collapse>
            </CardAccordion>
            </Accordion>
          <OutboundLink href="https://bit.ly/womenworksmentorsignupnew" target="_blank" rel="noreferrer">
            <ButtonRegistration type="button">Fill out the mentor registration form</ButtonRegistration>
          </OutboundLink>
          </Col>
        </Row>
      </Container>
    </SectionBackground>
  )
}