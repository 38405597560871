import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Link from "../components/link";

const HeForSheSection4Background = styled.div`
  min-height: 439px;
  background: linear-gradient(to left, ${props => props.backgroundColorRight} 50%, ${props => props.backgroundColorLeft} 50%);
  color: ${props => props.color};
  position:relative;
  overflow:hidden;
  @media(max-width: 768px){
    max-width: 100% !important;
    min-width: 100% !important;
    background: linear-gradient(to top, ${props => props.backgroundColorRight} 50%, ${props => props.backgroundColorLeft} 50%);
  }
`

const HeForSheSection4Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom:15px;
  text-align:left;
  white-space: pre-line;
  @media(max-width:991px){
    min-height:248px;
  }
  @media(max-width:768px){
    font: 2.5rem/3rem Abril Fatface;
    min-height:0;
    margin-left:15px;
    margin-right:15px;
  }
  @media(min-width:1200px) {
    min-height: 127px;
  }
`
const SectionItem = styled(Col)`
  text-align:center;
  display: inline-block;
  @media(min-width:769px){
    margin-top:130px;
    margin-bottom:130px;
  }
  @media(max-width:768px){
    margin-top:50px;
    margin-bottom:50px;
  }
`
const SectionP = styled.p`
  margin-bottom:35px;min-height:84px;
  text-align:left;
  @media(max-width:991px){
    min-height:140px;
  }
  @media(max-width:768px){
    min-height:0;
    margin-left:15px;
    margin-right:15px;
  }
`

const ColumnSection = styled(Col)`
  background-color: ${props => props.backgroundColor};
  font: 20px/28px Roboto;
  @media(min-width: 769px){
    max-width: 46% !important;
    min-width: 46% !important;
  }
  @media(max-width: 768px){
    max-width: 100% !important;
    min-width: 100% !important;
  }
`

const ButtonFill6 = styled(Button)`
  height: 60px;
  color: ${props => props.color} !important;
  background-color: ${props => props.backgroundColor} !important;
  border-color: ${props => props.borderColor} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  display: ${props => props.displayButton};
  &:hover {
    color: ${props => props.colorHover} !important;
    background-color: ${props => props.backgroundColorHover} !important;
    border-color: ${props => props.borderColorHover} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.colorPressed} !important;
    background-color: ${props => props.backgroundColorPressed} !important;
    border-color: ${props => props.theme.borderColorPressed} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:20px;
  }
`

const RightColumnContent = [
    "Build meaningful connections with professional female peers and mentees",
    "Spread and capitalize on your knowledge beyond your work",
    "Wider reach of impact and exposure beyond your immediate first circles"
]

export default function HeForSheSection4(props) {
  return (
  <HeForSheSection4Background color={props.TextColor} backgroundColorLeft={props.BackgroundLeft} backgroundColorRight={props.BackgroundRight}>
    <Container css={css`
    @media(max-width:768px){
      padding:0;margin:0;
    }`}>
      <Row css={css`justify-content:space-between;@media(max-width:768px){
      padding:0;margin:0;width:100vw;
      }`}>
          <ColumnSection backgroundColor={props.BackgroundLeft}
          css={css`
              float:left;
              `}>
            <SectionItem>
              <HeForSheSection4Title>
                Who can be a mentor?
              </HeForSheSection4Title>
              <SectionP>
                <b>Men in leadership positions who believe that everyone is born free and equal,</b> who are ready to play their part in eliminating gender bias, discrimination, and violence in all aspects of professional and personal life, so that the world can benefit from the diversity and strengths that everyone, regardless of gender, brings to the table; both at home and in our professional lives.
              </SectionP>
              <Link to={props.ButtonLinkLeft}>
                <ButtonFill6 variant="outline-light" 
                color={props.ButtonColorLeft} 
                backgroundColor={props.ButtonBackgroundLeft} 
                borderColor={props.ButtonBorderLeft} 
                colorHover={props.ButtonColorHoverLeft}
                backgroundColorHover={props.ButtonBackgroundHoverLeft}
                borderColorHover={props.ButtonBorderHoverLeft}
                colorPressed={props.ButtonColorPressedLeft}
                backgroundColorPressed={props.ButtonBackgroundPressedLeft}
                borderColorPressed={props.ButtonBorderPressedLeft}
                displayButton={props.DisplayButton}
                >
                    {props.ButtonTextLeft}
                </ButtonFill6>
              </Link>
            </SectionItem>
          </ColumnSection>
          <ColumnSection backgroundColor={props.BackgroundRight} 
          css={css`
          padding:0;
          float:right;
          `} xs="12" >
            <SectionItem>
              <HeForSheSection4Title>
                What's in it for me as a mentor?
              </HeForSheSection4Title>
              <div css={css`text-align:left;
                @media(max-width:768px){
                margin-left:15px;
                margin-right:15px;}`}>{
                RightColumnContent.map(content=>(
                    <div css={css`display:flex;`}>
                        &bull;
                    <p>
                        {content}
                    </p>
                    </div>
                ))}
              </div>
              <Link to={props.ButtonLinkRight}>
                <ButtonFill6 variant="outline-light" 
                  color={props.ButtonColorRight} 
                  backgroundColor={props.ButtonBackgroundRight} 
                  borderColor={props.ButtonBorderRight} 
                  colorHover={props.ButtonColorHoverRight}
                  backgroundColorHover={props.ButtonBackgroundHoverRight}
                  borderColorHover={props.ButtonBorderHoverRight}
                  colorPressed={props.ButtonColorPressedRight}
                  backgroundColorPressed={props.ButtonBackgroundPressedRight}
                  borderColorPressed={props.ButtonBorderPressedRight}
                  displayButton={props.DisplayButton}
                >
                  {props.ButtonTextRight}
                </ButtonFill6>
              </Link>
            </SectionItem>
          </ColumnSection>
      </Row>
    </Container>
  </HeForSheSection4Background>
  )
}