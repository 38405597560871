import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Container from "react-bootstrap/Container";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const HeForSheSection1Background = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  min-height: 720px;
  overflow: hidden;
  @media(max-width:767px){
    min-height: 0px;
  }
`

const HeForSheSection1Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom:35px;
  min-height: 132px;
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:17.5px;
  }
`
const SectionItem = styled.div`
  margin-top:61px;
  margin-bottom:100px;
  display: inline-block;
  @media(max-width:767px){
    margin-top:0;
    margin-bottom:50px;
  }
`



const ButtonFill6 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:20px;
  }
`

export default function HeForSheSection1(props) {
  return (
  <HeForSheSection1Background backgroundColor={props.Background} color={props.TextColor}>
      <img alt="WomenWorks - He For She" src="/page-he-for-she-section1-img1.jpg" 
        css={css`
          @media(max-width:991px) {
            display:none;
          }
          @media(min-width:992px) {
            position: absolute;
            right: 0;
            top:155px;
            height: 691;
            max-width: 50vw;
            object-fit: cover;
          }
          @media(min-width:1600px) {
            display:none;
          }
      `}></img>
      <Container>
          <SectionItem>
            <Row>
              <Col xs="12" lg="5" css={css`margin-top:70px;@media(max-width:767px){margin-top:0px;}`} >
                <HeForSheSection1Title>
                    Stronger TogetHER byWomenWorks
                </HeForSheSection1Title>
              </Col>
              <Col xs="12" lg="7">
                <img alt="WomenWorks - He For She" src="/page-he-for-she-section1-img1.jpg" 
                  css={css`
                    @media(max-width:767px) {
                      width:100%;
                      margin-bottom:15px;
                    }
                    @media(min-width:768px) {
                      object-fit: cover;
                      height: 250px;
                      width:100%;
                      margin-bottom:30px;
                      object-position: 15% 15%;
                    }
                    @media(min-width:992px) {
                      display:none;
                    }
                    @media(min-width:1600px) {
                      display:block;
                      position:absolute;
                      object-fit:scale-down;
                      width: 832px;
                      height: 800px;
                      margin-left: 15%;
                    }
                `}></img>
              </Col>
              <Col xs="12" lg="5" css={css`text-align: justify;`}>
                <p css={css`margin-bottom:20px;min-height:54px;max-height:216px;`}>
                  Stronger Together Closed-Group Mentorships <b> build meaningful connections between men and women in order to grow and advance in their professional life.</b> All men will undergo a tight vetting process by WomenWorks.
                </p>
                <OutboundLink target="_blank" rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSeGwQ9gNxGGKxK8xj7y9L331-qHmHnJfBIjAApiWti-0yFTcw/viewform?usp=pp_url&entry.608700988=Closed+Group+Mentorship">
                  <ButtonFill6 variant="outline-light" css={css`@media(max-width:991px) {width:100%;}`}>
                    Sign up for group mentorship
                  </ButtonFill6>
                </OutboundLink>
              </Col>
            </Row>
          </SectionItem>
      </Container>
  </HeForSheSection1Background>
  )
}

